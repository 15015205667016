<template>
  <v-dialog v-model="dialog" width="unset" overflow="hidden">
    <template v-slot:activator="{ on, attrs }" style="overflow: hidden;"  >
      <v-btn
      color="secondary"
      elevation="2"
      raised
      class="primary--text"
      v-bind="attrs"
      v-on="on"
      @click="ShowAlbum()"
      >
      <span>View</span>
    </v-btn>
  </template>
  <v-carousel hide-delimiters height="unset" style="overflow: hidden;" class="hidden-md-and-down">
   <v-carousel-item style="overflow: hidden;"
   v-for="image in Images"
   :key="image">
   <v-row
   class="fill-height"
   align="center"
   justify="center"
   >
   <img :src="getImgUrl2(image)" style="overflow: hidden; display: inline-block; max-height:850px; min-height: 700px;"/>
 </v-row>
</v-carousel-item> 
</v-carousel>
<v-carousel hide-delimiters height="unset" style="overflow: hidden;" class="hidden-lg-and-up">
   <v-carousel-item style="overflow: hidden;"
   v-for="image in Images"
   :key="image">
   <v-row
   class="fill-height"
   align="center"
   justify="center"
   >
   <img :src="getImgUrl2(image)" style="overflow: hidden; display: inline-block; max-height:850px;"/>
 </v-row>
</v-carousel-item> 
</v-carousel>
</v-dialog>
</template>
<script>
  const axios = require("axios");

  export default {
    data() {
      return {
        dialog: false,
        Name: "",
        titel: "Error in fetching Albums. Try again later...",
        Images: [],
      };
    },
    props: ["Album"],
    methods: {

      getImgUrl2(image) {
        console.log(image)
        return "https://bsaoc.be/albums/" + image
      },
      ShowAlbum: function() {
        var postData = {
          Album: this.Album.Name
        };
        console.log(this.Album)
        let axiosConfig = {
          headers: {
          // "auth-token": store.state.token,
        },
      };
      const url = `https://bsaoc.be:8443/albums/showalbum`;
      axios
      .post(url, postData, axiosConfig)
      .then((res) => {
        console.log(res.data);
        this.Images = res.data
        console.log()
          //Users terug callen om de data te updaten
          // store.dispatch("callUsersAPI");
        })
      .catch((err) => {
        console.log(err);
      });
    },


  },
};
</script>
