<template>
  <v-dialog v-model="show" width="unset" overflow="hidden">
    <div class="background">
      <v-container fill-height fluid>
        <v-row justify="space-around" style="margin-top: -3%">
          <v-col justify-self="center">
            <v-card
              elevation="5"
              class="pa-3 mx-5"
              justify-self="center"
              style="
                max-width: 50vh;
                min-width: 30vh;
                max-height: 80vh;
                min-height: 32vh;
              "
            >
              <!-- link to https://midflandersbsaoc.wixsite.com/bsa-oc-be-mid-flande -->
              <a href="https://midflandersbsaoc.wixsite.com/bsa-oc-be-mid-flande">
                More info and registation here
              </a>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-dialog>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  data() {
    return {};
  },
  props: ["visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>