<template>
 <div class="background">
  <v-container fill-height fluid>
    <v-row justify="space-around" style="margin-top: 0%;">
      <v-col md="6" justify-self="center">
        <v-card elevation="5" class="pa-5">
          <v-card-title>Contact</v-card-title>
           <p>You can join our Facebook group <a href="https://www.facebook.com/groups/1555001658140156" target="_new" >Here</a>.</p>          
            <p>For Errors in the website you can contact website@bsaoc.be.</p>
            <p>If you want to connect with other motorcycle enthousiast,</p>
            <p>You can contact us via sandra_rigo@hotmail.com for info about the club.</p>
            <p>We are also present on instagram <a href="https://www.instagram.com/bsa_oc_belgium/" target="_new"> @bsa_oc_belgium </a> and on <a href="https://www.facebook.com/BSAOCBelgium" target="_new">facebook</a> </p>
      </v-card>
    </v-col>
  </v-row>
</v-container>  
</div>
</template>

<style lang="scss" scoped>
.background {
  height: 100%;
  width: 100%;
  background-image: url(../assets/background.jpg);
  background-size: cover;

}
</style>

<script>

  export default {
    data() {
      return {
      };
    },
    methods: {
    },
  };
</script>