<template>
    <v-container class="home" fluid>

      <TimelineComp fill-height :items="items" />
      
    </v-container>
</template>
<style scoped>
.home {
  height: 100%;
  width: 100%;
  background-image: url(../assets/background.jpg);
  background-size: cover;

}
</style>
<script>
import TimelineComp from '@/components/TimelineComp.vue'

  export default {
    components: {
        TimelineComp,

    },
    data() {
      let items = [
        {title:"IR 1964", content:" Germany - Burgen "},
        {title:"IR 1965", content:" Isle of Man - Ramsey "},
        {title:"IR 1966", content:" Austria - Vienna "},
        {title:"IR 1967", content:" Italy - Milan "},
        {title:"IR 1968", content:" France - Argentart "},
        {title:"IR 1969", content:" England - Coombe Martin "},
        {title:"IR 1970", content:" Austria - Maria Tafell "},
        {title:"IR 1971", content:" Italy - Lake Garda "},
        {title:"IR 1972", content:" Czechoslovakia - Frantiskovy Lanze "},
        {title:"IR 1973", content:" England - Tenby "},
        {title:"IR 1974", content:" Austria - Seefeld Tirol "},
        {title:"IR 1975", content:" Italy - Follonica "},
        {title:"IR 1976", content:" Germany - Karlstal "},
        {title:"IR 1977", content:" Netherlands - Elst "},
        {title:"IR 1978", content:" Czechoslovakia - Zandor Ceska Lipa "},
        {title:"IR 1979", content:" England - Great Yarmouth "},
        {title:"IR 1980", content:" Luxembourg "},
        {title:"IR 1981", content:" Germany - Ober Ramstadt "},
        {title:"IR 1982", content:" Netherlands - Noordwijk "},
        {title:"IR 1983", content:" Denmark - Langeland "},
        {title:"IR 1984", content:" England - St Leonards "},
        {title:"IR 1985", content:" Belgium - Houthalen "},
        {title:"IR 1986", content:" Norway - Lillehammer "},
        {title:"IR 1987", content:" France - Saint Cezaire "},
        {title:"IR 1988", content:" Germany - Wedel "},
        {title:"IR 1989", content:" Sweden - Gothenburg "},
        {title:"IR 1990", content:" USA - Steubenville, Ohio "},
        {title:"IR 1991", content:" Netherlands - Nijmegen "},
        {title:"IR 1992", content:" Denmark - Hillerod "},
        {title:"IR 1993", content:" England - Cotswolds "},
        {title:"IR 1994", content:" Belgium - Ronse "},
        {title:"IR 1995", content:" Italy - Lago Di Vico (Viterbo) "},
        {title:"IR 1996", content:" New Zealand - Upper Hutt "},
        {title:"IR 1997", content:" Norway - Notodden "},
        {title:"IR 1998", content:" Scotland - Bunchrew Inverness "},
        {title:"IR 1999", content:" France - Clearmont-Ferrand "},
        {title:"IR 2000", content:" Netherlands - Uitdam "},
        {title:"IR 2001", content:" Australia - Victoria "},
        {title:"IR 2002", content:" Italy - Montalto Di Castro Marina, Viterbo, Toscana "},
        {title:"IR 2003", content:" Sweden - Mölle Hässle "},
        {title:"IR 2004", content:" Germany - Krossinsee Berlin "},
        {title:"IR 2005", content:" Denmark - Mors "},
        {title:"IR 2006", content:" USA - Brimfield MA Ohio Valley "},
        {title:"IR 2007", content:" Belguim - Jabbeke "},
        {title:"IR 2008", content:" Isle of Man - Union Mills "},
        {title:"IR 2009", content:" Netherlands - Valkenburg "},
        {title:"IR 2010", content:" New Zealand - Christchurch Canterbury "},
        {title:"IR 2011", content:" Germany - Loreley "},
        {title:"IR 2012", content:" Sweden - Degeberga "},
        {title:"IR 2013", content:" Norway - Valldal "},
        {title:"IR 2014", content:" USA - Sonoma County, Northern California "},
        {title:"IR 2015", content:" Denmark - Vejen "},
        {title:"IR 2016", content:" Italy - Lago Di Vico, Viterbo "},
        {title:"IR 2017", content:" Netherlands - Ijhorst "},
        {title:"IR 2018", content:" South Australia - Victoria "},
        {title:"IR 2019", content:" Great Britain - Moreton in Marsh "},
        {title:"IR 2020", content:" Czech Republic - Louti Lake Slapy "},
        {title:"IR 2021", content:" Germany - Cancelled",src:"germany2021.jpg",event:"germany"},
        {title:"Elfstedentocht 2021",content:"30 july - 1 august in Noord Nederland", event:"elfstedentocht",src:"elfsteden2021.png"},
        {title:"John bull 2022", content:"Belgium - Passendale", event:"jb2022",src:"johnbull2022.jpg"},
        {title:"IR 2022", content:" Belgium - Thieux ", event:"ir2022",src:"IR2022.jpg"},
        {title:"John bull 2023", content:" Belgium - Lommel ", event:"jb2023",src:"johnbull2023.jpg"},
        {title:"Elfstedentocht 2023", content:" Netherlands -  Oudega"},
        {title:"Summercamp 2023", content:" Netherlands - Earnewald"},
        {title:"IR 2023", content:" South Australia - ", event:"ir2023", clickable:true},
        {title:"How does this work?!", content:"Click me for more info!!", clickable:true, event:"timeline"},
        {title:"John bull 2024", content:" Belgium - Bellingen-Pepingen ", event:"jb2024",src:"johnbull2024.jpg"},
      ];
      return { items };
    },
  };
</script>