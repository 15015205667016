<template overflow="hidden">
  <v-dialog v-model="show" width="unset" overflow="hidden">
  <v-stepper
      v-model="e6"
      vertical
    >
      <v-stepper-step
        :complete="e6 > 1"
        step="1"
      >
        Event timeline
        <small>How does it work?</small>
      </v-stepper-step>
  
      <v-stepper-content step="1">
        <v-btn
          color="primary"
          @click="e6 = 2"
        >
          Tell me
        </v-btn>
        <v-btn 
        @click="close()"
        text>
          I already know
        </v-btn>
      </v-stepper-content>
  
      <v-stepper-step
        :complete="e6 > 2"
        step="2"
      >
        Moving around
      </v-stepper-step>
  
      <v-stepper-content step="2">
        <p>You can either drag the timeline around like swiping your phone or you can use shift+scrolling on PC and mac.</p>
        <p>The timeline is also occupied with a goto begin and goto now button wich may be usefull if you find yourself lost in time.</p>
        <v-btn
          color="primary"
          @click="e6 = 3"
        >
          Next
        </v-btn>
        <v-btn
        @click="close()"
         text>
          I got this!
        </v-btn>
      </v-stepper-content>
  
      <v-stepper-step
        :complete="e6 > 3"
        step="3"
      >
        Where are the old info buttons?
      </v-stepper-step>
  
      <v-stepper-content step="3">
        <p>Clicking or tapping the balloon in wich the event is present will pop up a screen just like this one. </p>
        <p>How do i get out you may wonder? You click on any side of the screens and they dissapear.</p>
        <v-btn
          color="primary"
          @click="e6 = 4"
        >
          Next
        </v-btn>
        <v-btn 
        @click="close()"
        text>
          I got this!
        </v-btn>
      </v-stepper-content>
  
      <v-stepper-step step="4">
        Why is nothing happening?
      </v-stepper-step>
      <v-stepper-content step="4">
        <p>At the time of creation the timeline isn't complete due to lack of info, photos or time. These will be updated in the future, in the time between check out the photos gallery!</p>
        <v-btn
          @click="close()"
          color="primary"
        >
          Done!
        </v-btn>
      </v-stepper-content>
    </v-stepper>

</v-dialog>
</template>

<style lang="scss" scoped>

</style>

<script>

  export default {
    data() {
      return {
        e6:1,
      };
    },
    methods: {
      close(){
        this.e6 = 1;
        this.$emit('close')
      },
    },
    props:['visible'],
    computed:{
      show: {
        get() {
          return this.visible
        },
        set(value){
          if(!value){
            this.$emit('close')
          }
        }
      }
    }
  };
</script>