<template>
 <div class="background">
    <div class="scroll">
  <v-container fill-height fluid>
    <v-row justify="space-around" style="margin-top: %;">
      <v-col md="6" justify-self="center">
        <v-card class="pa-5 pm-15 my-5">
          <v-card-title>
            <h1 class="test">
              Who are we?
            </h1>
          </v-card-title>
          <p></p>
          <p>The BSA Owners Club Belgium was established in 1982. We are an enthusiastic motorcycle club with a passion for BSA motorcycles.</p>
          <p>Our goal is to bring together people with that same passion for motorcycles manufactured at Birmingham Small Arms Company, B.S.A. Cycles Ltd and B.S.A. Motorcycles Ltd.
          We are an association without lucrative purpose of about 100 members.</p>
          <p>There are 4 branches in Belgium: West Flanders, East Flanders, Brabant and Limburg.</p>
          <p>We organise multiple annual events. The first one of the year is our Cakebakingtour.

          In the weekend of Labour day, we organise the John Bull Rally and in June we host the M20 event. The General Meeting takes place every year. And last, but not least: The annual International Rally or alternatively the Summercamps. </p>
          <p>The International Rally and Summercamp events are each year in a different country.</p>
        </v-card>
        <v-card class="pa-5 pm-15 my-5">
          <v-card-title>        
            <h1 class="test">
              What do we do?
            </h1>
          </v-card-title>
          <p></p>
          <p> For anyone interested in BSA motorcycles. If you like to ride together, talk about BSA, meet people, etc... our club is the place to be.</p>
          <p> - Our 3 monthly magazine 'The Best'</p>
          <p> - Club members organize different rides that you can participate in.</p>
          <p> - Always the opportunity to ask technical questions. </p>
          <p> </p>
        </v-card>
        <v-card class="pa-5 pm-15 my-5">
          <v-card-title>        
            <h1 class="test">
              How to join?
            </h1>
          </v-card-title>
          <p></p>
          <p>If you want to become a member of the club, please fill in the registration form and you will get an email with the confirmation.</p>
          <p> We are affiliated with the BFOV and the corresponding insurance company Marsh wich is the most beneficial for oldtimers.</p>
          <p>There is an annual membership fee to receive the club magazine and to be a member of the <a target="_blank" rel="noopener noreferrer" href="https://www.bfov-fbva.be/">Belgian Historic Vehicle Association</a> (formerly BFOV). Wich allows you to get an inscurance plan at a reduced cost. The annual fee is 20 euro's to be transfered to the account: BE53 7805 8256 6653 with your full name and email as reference.</p>
          <p> Please fill in the registration form <a rel="noopener noreferrer" href="https://www.bsaoc.be/register">here</a> </p>
        </v-card>
        <v-card class="pa-5 pm-15 my-5">
          <v-card-title>
            <h1 class="test">
              Privacy Declaration
            </h1>
          </v-card-title>
          <p></p>
          <p> None of your data will be passed on to other organizations, nor will we sell it.</p>
          <p> We take every meassure possible to keep your data safe and are willing to delete at your request</p>
          <p> All data is stricly used for membership management being: The club magazine and checking membership fee payements. Both stay within the respective board member(s) who need to use the specific information. </p>
        </v-card>
        <br><br><br><br><br>
      </v-col>
    </v-row>
    
  </v-container>  
</div>
</div>
</template>

<style lang="scss" scoped>
.background {
  height: 100%;
  width: 100%;
  background-image: url(../assets/background.jpg);
  background-size: cover;

}
.scroll {
  overflow-y: scroll;
  max-height: 93vh;
  overflow-x: hidden;

}
</style>

<script>

  export default {
    data() {
      return {
      };
    },
    methods: {
    },
  };
</script>