<template>
 <div class="background">
  <v-container fill-height fluid>
    <v-row justify="space-around" style="margin-top: -3%;">
      <v-col md="6" justify-self="center">
        <v-card elevation="5" class="pa-5">
          <v-card-title>Register Success!</v-card-title>
            <p class="my-5 mx-15">
              You will receive an email confirming your registration wich contains a password to your personal account to read the club magazine on the website.
            </p>
      </v-card>
    </v-col>
  </v-row>
</v-container>  
</div>
</template>

<style lang="scss" scoped>
.background {
  height: 100%;
  width: 100%;
  background-image: url(../assets/background.jpg);
  background-size: cover;

}
</style>

<script>

  export default {
    data() {
      return {
      };
    },
    methods: {
    },
  };
</script>